import React, { useCallback, useMemo, useState } from "react";
import { differenceInDays, format } from "date-fns";
import { es } from "date-fns/locale";
import { Helmet } from "react-helmet";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TRANSLATIONS = {
  en: {
    title: "Conflict Duration Tracker",
    ukraineConflict: "Ukraine Conflict",
    israelConflict: "Israel Conflict",
    daysElapsed: "Days elapsed",
    started: "Started",
    years: "Years",
    languageSelect: "Select Language",
  },
  es: {
    title: "Duración de Conflictos",
    ukraineConflict: "Conflicto de Ucrania",
    israelConflict: "Conflicto de Israel",
    daysElapsed: "Días transcurridos",
    started: "Iniciado",
    years: "Años",
    languageSelect: "Seleccionar Idioma",
  },
};

const UKRAINE_CONFLICT_START = new Date("2022-02-24");
const ISRAEL_CONFLICT_START = new Date("2023-10-07");

const formatDate = (date, language) => {
  return format(
    date,
    language === 'en' ? "EEE, MMM d, yyyy" : "EEE, d 'de' MMMM 'de' yyyy",
    { locale: language === 'en' ? undefined : es }
  );
};

const emojiFlagPlugin = {
  id: 'emojiFlagPlugin',
  afterDatasetsDraw: (chart, args, options) => {
    const { ctx } = chart;
    ctx.save();

    const xAxis = chart.scales.x;
    const yAxis = chart.scales.y;

    chart.data.datasets[0].data.forEach((value, index) => {
      const flagY = yAxis.getPixelForValue(index);
      const flagX = xAxis.getPixelForValue(value);

      ctx.font = '20px Arial';
      ctx.textAlign = 'left';
      ctx.textBaseline = 'middle';
      ctx.fillText(index === 0 ? '🇺🇦' : '🇮🇱', flagX - 30, flagY);
    });

    ctx.restore();
  }
};

const ConflictDurationTracker = () => {
  const [language, setLanguage] = useState("en");
  const [isDarkMode, setIsDarkMode] = useState(false);

  const today = useMemo(() => new Date(), []);

  const { ukraineDays, israelDays, ukraineYears, israelYears } = useMemo(() => {
    const ukraineDays = differenceInDays(today, UKRAINE_CONFLICT_START);
    const israelDays = differenceInDays(today, ISRAEL_CONFLICT_START);
    return {
      ukraineDays,
      israelDays,
      ukraineYears: ukraineDays / 365,
      israelYears: israelDays / 365,
    };
  }, [today]);

  const t = TRANSLATIONS[language];

  const chartOptions = useMemo(
    () => ({
      indexAxis: "y",
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: t.title,
          color: isDarkMode ? "rgba(255, 255, 255, 0.8)" : "rgba(0, 0, 0, 0.8)",
          font: {
            family:
              "'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
            size: 18,
            weight: "600",
          },
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const days = Math.round(context.parsed.x * 365);
              return `${days} ${
                language === "en" ? "days" : "días"
              } (${context.parsed.x.toFixed(2)} ${
                language === "en" ? "years" : "años"
              })`;
            },
          },
        },
        emojiFlagPlugin: {},
      },
      scales: {
        x: {
          beginAtZero: true,
          title: {
            display: true,
            text: t.years,
            color: isDarkMode
              ? "rgba(255, 255, 255, 0.8)"
              : "rgba(0, 0, 0, 0.8)",
            font: {
              family:
                "'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
              size: 14,
              weight: "500",
            },
          },
          ticks: {
            color: isDarkMode
              ? "rgba(255, 255, 255, 0.8)"
              : "rgba(0, 0, 0, 0.8)",
            font: {
              family:
                "'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
              size: 12,
            },
          },
        },
        y: {
          ticks: {
            color: isDarkMode
              ? "rgba(255, 255, 255, 0.8)"
              : "rgba(0, 0, 0, 0.8)",
            font: {
              family:
                "'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
              size: 12,
            },
          },
        },
      },
    }),
    [t, isDarkMode, language]
  );

  const chartData = useMemo(
    () => ({
      labels: [t.ukraineConflict, t.israelConflict],
      datasets: [
        {
          data: [ukraineYears, israelYears],
          backgroundColor: [
            "rgba(54, 162, 235, 0.7)",
            "rgba(75, 192, 192, 0.7)",
          ],
          borderColor: ["rgb(54, 162, 235)", "rgb(75, 192, 192)"],
          borderWidth: 1,
          borderRadius: 5,
          borderSkipped: false,
        },
      ],
    }),
    [t, ukraineYears, israelYears]
  );

  const handleLanguageChange = useCallback((e) => {
    setLanguage(e.target.value);
  }, []);

  const toggleDarkMode = useCallback(() => {
    setIsDarkMode((prevMode) => !prevMode);
  }, []);

  return (
    <div
      className={`min-h-screen ${
        isDarkMode
          ? "bg-gray-900"
          : "bg-gradient-to-br from-purple-100 via-pink-100 to-blue-100"
      } p-4 sm:p-8`}
    >
      <Helmet>
        <html lang={language} />
        <title>{t.title}</title>
        <meta
          name="description"
          content={`Track the duration of ongoing conflicts in Ukraine and Israel. Updated daily with visualizations.`}
        />
        <meta
          name="keywords"
          content="conflict tracker, war duration, Ukraine conflict, Israel conflict, geopolitical events"
        />

        {/* Canonical URL */}
        <link
          rel="canonical"
          href="https://www.conflict-tracker.cqea.digital/"
        />

        {/* Favicon */}
        <link rel="icon" href="%PUBLIC_URL%/favicon.svg" type="image/svg+xml" />
        <link rel="alternate icon" href="%PUBLIC_URL%/favicon.ico" />

        {/* Apple Touch Icon */}
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/apple-touch-icon.png" />

        {/* Manifest */}
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />

        {/* Preconnect to external resources */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />

        <style>
          {`
            @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
            
            body {
              font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              color: ${isDarkMode ? "#ffffff" : "#1d1d1f"};
            }
            .custom-shadow {
              box-shadow: 0 22px 70px 4px rgba(0, 0, 0, 0.56);
            }
            h1, h2 {
              font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
            }
            h1 { font-weight: 700; }
            h2 { font-weight: 600; }
          `}
        </style>
      </Helmet>{" "}
      <div className="container mx-auto">
        <div
          className={`max-w-4xl mx-auto custom-shadow rounded-2xl overflow-hidden ${
            isDarkMode ? "bg-gray-800" : "bg-white"
          }`}
        >
          <div className="p-4 sm:p-8">
            <div className="flex justify-between items-center mb-6">
              <h1
                className={`text-2xl sm:text-4xl font-bold ${
                  isDarkMode ? "text-white" : "text-gray-900"
                }`}
              >
                {t.title}
              </h1>
              <div className="flex items-center space-x-4">
                <select
                  value={language}
                  onChange={handleLanguageChange}
                  className={`px-2 py-1 border rounded-md text-sm ${
                    isDarkMode
                      ? "bg-gray-700 text-white border-gray-600"
                      : "bg-white text-gray-900 border-gray-300"
                  }`}
                  style={{
                    fontFamily:
                      "'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                  }}
                >
                  <option value="en">🇺🇸</option>
                  <option value="es">🇪🇸</option>
                </select>
                <button
                  onClick={toggleDarkMode}
                  className={`p-2 rounded-full ${
                    isDarkMode ? "bg-gray-700" : "bg-gray-100"
                  }`}
                  aria-label={
                    isDarkMode ? "Switch to light mode" : "Switch to dark mode"
                  }
                >
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d={
                        isDarkMode
                          ? "M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5ZM12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z"
                          : "M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5ZM12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                      }
                      fill={isDarkMode ? "#FDB813" : "#1D1D1F"}
                    />
                    <path
                      d="M12 3V1M12 23V21M3 12H1M23 12H21M5.5 5.5L4 4M18.5 18.5L20 20M18.5 5.5L20 4M5.5 18.5L4 20"
                      stroke={isDarkMode ? "#FDB813" : "#1D1D1F"}
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="space-y-6 sm:space-y-8">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                <div
                  className={`${
                    isDarkMode
                      ? "bg-blue-900 bg-opacity-30"
                      : "bg-blue-50 bg-opacity-70"
                  } p-4 sm:p-6 rounded-xl`}
                >
                  <h2
                    className={`text-xl sm:text-2xl font-semibold ${
                      isDarkMode ? "text-blue-300" : "text-blue-700"
                    } mb-2 sm:mb-3`}
                  >
                    {t.ukraineConflict}
                  </h2>
                  <p
                    className={`${
                      isDarkMode ? "text-blue-200" : "text-blue-600"
                    } text-base sm:text-lg`}
                  >
                    {t.daysElapsed}: {ukraineDays}
                  </p>
                  <p
                    className={`text-sm ${
                      isDarkMode ? "text-blue-300" : "text-blue-500"
                    } mt-1 sm:mt-2`}
                  >
                    {t.started}: {formatDate(UKRAINE_CONFLICT_START, language)}
                  </p>
                </div>
                <div
                  className={`${
                    isDarkMode
                      ? "bg-teal-900 bg-opacity-30"
                      : "bg-teal-50 bg-opacity-70"
                  } p-4 sm:p-6 rounded-xl`}
                >
                  <h2
                    className={`text-xl sm:text-2xl font-semibold ${
                      isDarkMode ? "text-teal-300" : "text-teal-700"
                    } mb-2 sm:mb-3`}
                  >
                    {t.israelConflict}
                  </h2>
                  <p
                    className={`${
                      isDarkMode ? "text-teal-200" : "text-teal-600"
                    } text-base sm:text-lg`}
                  >
                    {t.daysElapsed}: {israelDays}
                  </p>
                  <p
                    className={`text-sm ${
                      isDarkMode ? "text-teal-300" : "text-teal-500"
                    } mt-1 sm:mt-2`}
                  >
                    {t.started}: {formatDate(ISRAEL_CONFLICT_START, language)}
                  </p>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="h-60 sm:h-80 w-full max-w-2xl">
                  <Bar
                    options={chartOptions}
                    data={chartData}
                    plugins={[emojiFlagPlugin]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConflictDurationTracker;